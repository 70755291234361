<!--
 * @Description: 弹窗组件
 * @Autor: Zhongyu
 * @Date: 2021-09-05 18:28:22
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-10 11:25:34
-->
<template>
  <div class="default-tip-modal">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :width="width"
      :modal="modal"
      custom-class="modal-custom"
    >
      <div>
        <slot></slot>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$emit('close')">
          取 消
        </el-button>
        <el-button type="primary" @click="$emit('ok')">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // 是否显示弹窗
    visible: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: "提示"
    },
    // 弹窗的宽度
    width: {
      type: String,
      default: "350px"
    },

    // 是否需要遮罩层
    modal: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
